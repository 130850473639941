import React, {useEffect, useState} from 'react';

import Header from './Sections/header/Header';
import Footer from "./Sections/footer/Footer";
import Gradient from "./Sections/Gradient";
import HowItWas from "./Sections/HowItWas/HowItWas";
import Cookies from "./components/Cookies/Cookies";
// @ts-ignore
import { getSpeakers, getPrograms } from './api';
// @ts-ignore
import Speakers, { Speaker } from "./Sections/Speakers/Speakers";
import Programs, { Hall } from "./Sections/Programs/Programs";
import Stub from "./components/Stub/Stub";
// @ts-ignore
import { data } from "./data.ts"

import './styles/fonts.scss'
import './App.scss';

function App() {
    const [speakers, setSpeakers] = useState<Speaker[]>([])
    const [halls, setHalls] = useState<Hall[]>([])

    useEffect(() => {
        getSpeakers().then(setSpeakers)
        getPrograms().then(setHalls)
    }, [])

    return (
        <div className="sbergile_talks_2024">
            <div className="sbergile_talks_2024__conteiner">
                <Gradient />
                <Header header={data.header} />

                <Stub />
                <Speakers speakers={speakers} title="Спикеры" />
                {/*<Programs halls={halls} title="Программа" />*/}
                <HowItWas howItWas={data.howItWas} />

                <Footer footer={data.footer} />
                <Cookies />
            </div>
        </div>
    );
}

export default App;
