import React from 'react';

import { Grid, Row, Col } from '../../utils/grid';

import './Footer.scss';

type FooterProps = {
    footer: {
        title: string
        mail: string
        subtitle: string
    }
}

const Footer = ({ footer }: FooterProps) => {
    const currentYear = new Date().getFullYear()

    return (
        <div className="footer">
            <div className="footer__title">
                {footer.title}
                <a href={`mailto:${footer.mail}`} className="footer__mail"> {footer.mail}</a>
            </div>
            <div className="footer__subtitle">
                {footer.subtitle}

                <div className="footer__info">
                    © 1997—{currentYear}
                    <a className="footer__bank" target="_blank" href="https://www.sberbank.ru/ru/"> ПАО Сбербанк</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;