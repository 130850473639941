import React, { useState, useCallback } from 'react';
import {useBreakpoint} from "../../utils/useBreakpoint";

import './HowItWas.scss';

type Item = { id: number; title: string; video: string; videoPreview?: string }

type HowItWasProps = {
    howItWas: {
        title: string;
        items: Item[]
    };
};

const HowItWas = ({ howItWas }: HowItWasProps) => {
    const useDevise = useBreakpoint();

    const [activeItem, setActiveItem] = useState(howItWas.items[0]);

    const handleItemClick = useCallback((e: React.MouseEvent<HTMLButtonElement>, id: number) => {
        e.stopPropagation()
        e.preventDefault()

        setActiveItem(howItWas.items.find((item) => item.id === id) || howItWas.items[0])
    }, [howItWas.items])

    return (
        <div className="howItWas">
            <div className="howItWas__title">{howItWas.title}</div>
            <div className="howItWas__items">
                {howItWas.items.map((item) => {
                    const isActive = activeItem.id === item.id

                    return (
                        <button key={item.id} className={`howItWas__items_item ${isActive ? 'howItWas__items_item--active' : ''}`} onClick={(e) => handleItemClick(e, item.id)}>
                            {item.title}
                        </button>
                    )
                })}
            </div>

            <iframe
                referrerPolicy="no-referrer"
                title="article-video"
                className="howItWas__video"
                src={activeItem.video}
                frameBorder="0"
                allow="fullscreen; picture-in-picture"
                webkitAllowFullScreen
                mozallowfullscreen />
        </div>
    );
}

export default HowItWas;
