import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { useBreakpoint } from "../../utils/useBreakpoint";

import './Speakers.scss';

export type Speaker = {
    id: number;
    fullName: string;
    imageUrl: string;
    workPlace?: string;
    workPosition?: string;
};

type SpeakersProps = {
    title: string;
    speakers: Speaker[]
};

const Speakers = ({ speakers: initialSpeakers, title }: SpeakersProps) => {
    const useDevise = useBreakpoint();
    const [speakers, setSpeakers] = useState<Speaker[]>([])

    const moreByClick = useMemo(() => {
        switch (useDevise.breakpoint) {
            case 'md':
            case 'lg': return 6;

            case 'xl': return 8;


            default: return 3;
        }
    }, [useDevise.breakpoint])

    const btnConditions = useMemo(() => {
        return {
            showMore: initialSpeakers.length > speakers.length,
            showBtns: initialSpeakers.length > moreByClick
        }
    }, [initialSpeakers, speakers, moreByClick])

    useEffect(() => {
        if (initialSpeakers.length !== 0) {
            if (initialSpeakers.length > moreByClick) {
                setSpeakers(initialSpeakers.slice(0, moreByClick))
            } else {
                setSpeakers(initialSpeakers)
            }
        }
    }, [initialSpeakers, moreByClick])

    const handleShowMore = useCallback(() => {
        setSpeakers(initialSpeakers.slice(0, speakers.length + moreByClick))
    }, [initialSpeakers, speakers, useDevise.breakpoint])

    const hideAll = useCallback(() => {
        setSpeakers(initialSpeakers.slice(0, moreByClick))
    }, [initialSpeakers, moreByClick])

    if (speakers.length === 0) {
        return null;
    }

    return (
        <div className="speakers">
            <div className="speakers__title">{title}</div>
            <div className="speakers__items">
                {speakers.map((speaker) => {
                    return (
                        <div key={speaker.id} className="speakers__item">
                            <div className="speakers__image">
                                <img src={speaker.imageUrl} alt={speaker.fullName} />
                            </div>
                            <div className="speakers__info">
                                <div className="speakers__name">{speaker.fullName}</div>
                                <div className="speakers__workPosition">{speaker.workPosition}</div>
                                <div className="speakers__workPlace">{speaker.workPlace}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {btnConditions.showBtns && (
                <button className="speakers__moreBtn" onClick={btnConditions.showMore ? handleShowMore : hideAll}>
                    {btnConditions.showMore ? 'Показать больше' : 'Скрыть все'}
                </button>
            )}
        </div>
    );
}

export default Speakers;
