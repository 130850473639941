export const data = {
  "header": {
    "title": "Конференция Sbergile 2024",
    "subtitle": "Конференция Sbergile – крупнейшая площадка для\u00A0обсуждения лучших практик гибкого управления в\u00A0бизнесе, продуктах и\u00A0командах. А\u00A0также – это место обмена опытом и\u00A0кейсами трансформаций среди русскоязычного ИТ-сообщества, которое уже на\u00A0протяжении пяти лет собирает тысячи зрителей.",
    "button": {
      "name": "Стать участником",
      "url": "https://www.sberbank.com/ru/promo/sbergile_talks_2024"
    },
    "cards": [
      {
        "label": "Дата:",
        "title": "23 октября",
        "info": "10:00 - 19:00"
      },
      {
        "label": "Место:",
        "title": "Москва",
        "info": "Кутузовский проспект, 32 "
      },
      {
        "label": " Формат:",
        "title": "Офлайн",
        "info": "+ онлайн-трансляция"
      }
    ]
  },
  "howItWas": {
    "title": "Как это было",
    "items": [
      {
        "id": 1,
        "title": "2023",
        "video": "https://rutube.ru/play/embed/a70e8b4f6e1f2a945ce92cdb823abdb0/"
      },
      {
        "id": 2,
        "title": "2022",
        "video": "https://rutube.ru/play/embed/2f797300d56fedbe844b369920b6a147/"
      },
      {
        "id": 3,
        "title": "2021",
        "video": "https://rutube.ru/play/embed/556e4e5ebad9b8caaeba3da94944d686/"
      }
    ]
  },
  "footer": {
    "title": "По всем вопросам – пишите на почту ",
    "subtitle": "Генеральная лицензия Банка России на\u00A0осуществление банковских операций №1481 от\u00A011.08.2015 г.",
    "mail": "sbergilehome@sberbank.ru",
  }
} as const
