

export const getSpeakers = async () => {
    return await fetch('/proxy/services/dictionary/shared/api/speakers/period/7')
        .then(res => res.json())
        .then(data => data.map((item) => {
            return {
                id: item.id,
                fullName: item.fullName,
                workPosition: item.workPosition,
                workPlace: item.workPlace,
                imageUrl: item.imageUrl
            }
        }))
        .catch(error => {
            console.warn(error)
            return []
        })
}

export const getPrograms = async () => {
    return await fetch('/proxy/services/dictionary/shared/api/v2/schedule/period/7')
        .then(res => res.json())
        .then(data => {
            console.log(data)
            return data[0].halls
        })
        .catch(error => {
            console.warn(error)
            return []
        })
}
