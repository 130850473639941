import React from 'react';

import './Stub.scss'

const Stub = () => {
    return (
        <div className="stub">
            <div className="stub__container">
                Программа конференции скоро появится
            </div>
        </div>
    );
};

export default Stub;